import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components'
import colors from '../components/color-css/color'

const ErrorWrapper = styled.div`
  text-align: center;
  width: 17.6875rem;
  margin: 3.25rem auto 7.4375rem auto;
`

const ErrorHeader = styled.p`
  font-family: museo-sans, sans-serif;
  font-size: 1.5rem;
  line-height: 2.1875rem;
  letter-spacing: -0.07px;
  color: ${colors.blue_17_percent};
  font-weight: 700;
  margin-bottom: 0.5rem;
`

const ErrorMessage = styled.p`
  font-family: museo-sans, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  letter-spacing: -0.05px;
  color: ${colors.grey_29_percent};
  margin-bottom: 0.125rem;
  height: 4.875rem;
`

const JoinLink = styled.a`
  font-family: museo-sans, sans-serif;
  font-size: 0.875rem;
  line-height: 1.875rem;
  color: ${colors.tooltip_icon};
  &:hover {
    color: ${colors.orange_darker};
    cursor: pointer;	
  }
`

const NotFoundPage = () => {

  const sitedata = useStaticQuery(graphql`
        query {
        site {
            siteMetadata {
                siteUrl
            }
        }
        }
    `);

  const siteUrl = sitedata?.site?.siteMetadata?.siteUrl

  return (
    <Layout
      isError={true}
      title="Error"
    >
      <SEO
        noindex={true}
        lang={'en'}
        title={'404: not found'}
      />
      <ErrorWrapper>
        <ErrorHeader>Page not found</ErrorHeader>
        <ErrorMessage>Sorry, but the page you are trying to view does not exist.</ErrorMessage>
        <JoinLink href={siteUrl}>Join the SDA today</JoinLink>
      </ErrorWrapper>
    </Layout>
  )
}

export default NotFoundPage
